<template>
<div class="card">
  <div class="check-filter" v-if="filterType !== 'lesiurTab'">
    <div class="card-header">
      <h2 class="mb-0">
        <b-button v-b-toggle="`checkboxFilter-${item.kind}`" class="btn btn-link lesiurtabFilter-header" variant="link">
          {{item.title}}
        </b-button>
      </h2>
    </div>

    <b-collapse :id="`checkboxFilter-${item.kind}`" v-model="isVisible">
      <b-card>
        <b-form-checkbox-group v-model="selected">
          <b-input-group v-if="item.kind==='hotel-name'" class="search-hotel-name">
            <b-form-input v-model="searchText" type="text" :placeholder="$t('search-result.search-hotel')"></b-form-input>
          </b-input-group>

          <ul class="list-group list-group-flush name-rating" v-if="item.kind==='hotel-name'">
            <li class="list-group-item" v-for="(d, inx) in hotelNameList" :key="inx">
              <b-form-checkbox :value="d.label" class="custom-control custom-checkbox">
                <span>{{d.label}}</span>
              </b-form-checkbox>
            </li>
          </ul>

          <ul class="list-group list-group-flush hotel-base" v-if="item.kind==='hotel-basis'">
            <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
              <b-form-checkbox :value="d.label" class="custom-control custom-checkbox">
                <span>{{d.text || d.label}}</span>
              </b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
      </b-card>
    </b-collapse>
  </div>

  <div class="check-filter" v-if="filterType === 'lesiurTab'">
    <div class="card-header">
      <h2 class="mb-0">
        <b-button v-b-toggle="`checkboxFilter-${item.kind}`" class="btn btn-link lesiurtabFilter-header" variant="link">
          {{item.title}}
        </b-button>
      </h2>
    </div>
    <b-collapse :id="`checkboxFilter-${item.kind}`" v-model="isVisible">
      <b-card v-if="item.kind==='hotel-name'">
        <b-form-checkbox-group v-model="selected">
          <b-input-group v-if="item.kind==='hotel-name'" class="search-hotel-name">
            <b-form-input v-model="searchText" type="text" :placeholder="$t('search-result.search-hotel')"></b-form-input>
          </b-input-group>
        </b-form-checkbox-group>
      </b-card>
      <b-card v-if="item.kind==='hotel-name'">
        <b-form-checkbox-group v-model="selected">
          <ul class="list-group list-group-flush name-rating" v-if="item.kind==='hotel-name'">
            <li class="list-group-item" v-for="(d, inx) in hotelNameList" :key="inx">
              <b-form-checkbox :value="d.label" class="custom-control custom-checkbox" @change="changeCheck">
                <span>{{d.label}}</span>
              </b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
      </b-card>
      <b-card v-if="item.kind==='hotel-basis'">
        <b-form-checkbox-group v-model="selected">
          <ul class="list-group list-group-flush hotel-base" v-if="item.kind==='hotel-basis'">
            <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
              <b-form-checkbox :value="d.label" class="custom-control custom-checkbox" @change="changeCheck">
                <span>{{d.text || d.label}}</span>
              </b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
      </b-card>
      <b-card v-if="item.kind==='hotel-dest'">
        <b-form-checkbox-group v-model="selected">
          <ul class="list-group list-group-flush hotel-base" v-if="item.kind==='hotel-dest'">
            <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
              <b-form-checkbox :value="d.label" class="custom-control custom-checkbox" v-on:change="changeCheck">
                <span>{{destinationCityName(d.text || d.label)}}</span>
              </b-form-checkbox>
            </li>
          </ul>
        </b-form-checkbox-group>
      </b-card>
    </b-collapse>
  </div>
</div>
</template>

<script>
import { BButton, BCollapse, VBToggle, BCard, BFormCheckboxGroup, BFormCheckbox,
  BFormInput, BInputGroup,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarCollapseItem',
  components: {
    BButton,
    BCollapse,
    BCard,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    filterType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      packages: 'GET_PACKAGES',
      destinationList: 'GET_DOMESTIC_DESTINATION',
    }),
    hotelNameList() {
      return this.getHotelNameList(this.searchText);
    },

  },
  data() {
    return {
      isVisible: true,
      selected: [],
      searchText: '',
    };
  },
  watch: {
    selected() {
      this.$emit('change', { kind: this.item.kind, value: this.selected });
    },
  },
  methods: {
    getHotelNameList(text) {
      const { item } = this;
      if (!item) return [];
      return text === '' ? item.subItems : item.subItems.filter((elm) => elm.label.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },
    destinationCityName(cityCode) {
      const filterArry = this.destinationList.filter((item) => item.code === cityCode);
      return filterArry.length > 0 ? filterArry[0].name : '';
    },
    changeCheck() {
      this.$emit('closePanel');
    },
  },
};
</script>

<style scoped>
  .list-group {
    padding-right: 0;
  }
  .custom-checkbox {
    margin-right: 1.5rem;
    display: block;
  }
  .custom-control {
    padding-left: 0;
  }
  .list-group-flush > .list-group-item {
    border-width: 0 0 2px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .star-rating .b-rating{
    border: none;
    background: transparent;
  }
  .star-rating .list-group-item{
    padding: 0;
  }
  .card-body{
    padding-top: 0;
  }
  .name-rating{
    filter: opacity(0.7);
  }
  /* .search-hotel-name{
    direction: ltr;
  } */
</style>
<style>
  .custom-control-label{
    justify-content: space-between;
    display: flex;
  }
  .check-filter .star-rating .b-rating span.b-rating-star-empty{
    display: none;
  }
  .check-filter .star-rating .custom-control-label::before, .check-filter .star-rating .custom-control-label::after{
    margin: 6px;
  }
  .card.check-filter .card-header .btn{
    direction: ltr;
  }
  .right-align .name-rating .custom-control-label{
    direction: rtl;
    text-align: right;
  }
  .right-align .name-rating .custom-control-label::before,
  .right-align .name-rating .custom-control-label::after{
    right: -1.5rem;
  }
  .left-align .name-rating .custom-control-label{
    direction: rtl;
    justify-content: flex-end;
  }
</style>
